<template>
  <section>
    <v-row>
      <v-col>
        <h4 class="pl-2">{{$_strings.order.SHIPPER_INFO}}</h4>
      </v-col>
      <v-col
        v-if="item.isLumpSum && userAccess.canUpdate && status === 'process'"
        class="d-flex justify-end"
      >
        <v-btn
          @click="navigateToPageLumpsum"
          :disabled="item.subOrder.isManualStatusUpdated"
          small
          width="250"
          color="teal"
          class="white--text"
        >
          {{$_strings.order.UPDATE_STATUS_LUMPSUM}}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-1">
        <v-data-table
          calculate-widths
          hide-default-footer
          class="grey lighten-4"
          :headers="headers"
          :items="item.subOrder.shipments"
        >
          <template v-slot:[`item.createdDate`]={item}>
            <p class="ma-0">{{dateFormat(item.createdDate)}}</p>
            <p class="ma-0" v-if="item.createdDate">{{timeFormat(item.createdDate)}}</p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Object,
      default: () => {},
    },
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$_strings.order.ORDER_NUMBER,
          value: 'shipmentsTitle',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.CREATE_DATE,
          value: 'createdDate',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.CUSTOMER_NAME,
          value: 'companyName',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    dateFormat,
    timeFormat,
    navigateToPageLumpsum() {
      const { pickupDateFrom, pickupDateTo } = this.filters;
      const params = {
        id: this.item.id,
        data: this.item,
        lumpsum: 'lumpsum',
      };
      this.$router.push({
        name: 'order-detail',
        params,
        query: {
          status: 'ON_PROCESS',
          cargoTitle: this.item.cargoTitle,
          pickupDateFrom,
          pickupDateTo,
        },
      });
    },
  },
};
</script>
