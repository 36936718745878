<template>
  <section>
    <section>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="userAccess.canUpdate"
            v-bind="attrs"
            v-on="on"
            color="red"
            @click.stop.prevent="setDialogConfirmation('reject')"
            :disabled="item.latestStatus == 'MENUNGGU_KONFIRMASI_DRIVER' || item.isAutoAssign"
          >
            mdi-close-circle-outline
          </v-icon>
        </template>
        <span>Tolak</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="userAccess.canUpdate"
            v-bind="attrs"
            v-on="on"
            color="primary"
            @click.stop.prevent="assigmentConfirmation"
            :disabled="item.latestStatus === 'MENUNGGU_KONFIRMASI_DRIVER' || item.isAutoAssign"
          >
            mdi-check-circle-outline
          </v-icon>
        </template>
        <span>Terima</span>
      </v-tooltip>
    </section>
    <DialogOrderConfirmation
      @fetchOrders="$emit('fetchOrders')"
      @checkOrderExist="checkOrderExist"
      ref="dialogOrderConfirmation"
    />
    <DialogAssignDriverVehicle
      ref="dialogSelectAssignDriverVehicle"
      title="Pilih Kendaraan dan Sopir"
      :service="serviceAssignDriverVehicle"
      :afterAction="() => $emit('fetchOrders')"
    />
  </section>
</template>
<script>
import axios from 'axios';
import { getDateTimeTz } from '@/helper/commonHelpers';
import DialogAssignDriverVehicle from '../Dialog/ChangeDriver.vue';
import DialogOrderConfirmation from '../Dialog/OrderConfirmation.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    status: {
      type: String,
      default: '',
    },
  },
  created() {
    source = CancelToken.source();
  },
  components: {
    DialogAssignDriverVehicle,
    DialogOrderConfirmation,
  },
  methods: {
    getDateTimeTz,
    serviceAssignDriverVehicle() {
      return this.$_services.order.saveDriver;
    },
    setDialogConfirmation(confirmation) {
      this.$refs.dialogOrderConfirmation.shipmentCargoId = this.item.id;
      if (confirmation === 'reject') {
        this.$refs.dialogOrderConfirmation.dialog = true;
        this.$refs.dialogOrderConfirmation.title = 'Konfirmasi Penolakan';
        this.$refs.dialogOrderConfirmation.form.confirm = false;
        return;
      }
      this.$refs.dialogOrderConfirmation.dialog = true;
      this.$refs.dialogOrderConfirmation.title = 'Konfirmasi Penerimaan';
      this.$refs.dialogOrderConfirmation.form.confirm = true;
    },
    setDialogSelectDriverVehicle() {
      this.$refs.dialogSelectAssignDriverVehicle.shipment = this.item.subOrder;
      this.$refs.dialogSelectAssignDriverVehicle.transportTypeId = this.item.subOrder.transportTypeId;
      this.$refs.dialogSelectAssignDriverVehicle.form.shipmentCargoId = this.item.id;
      this.$refs.dialogSelectAssignDriverVehicle.dialog = true;
    },
    checkOrderExist() {
      if (this.status === 'waiting') this.$emit('orderExists');
    },
    async handleAssignConfirmationLandTransport() {
      if (this.item.subOrder) {
        this.setDialogSelectDriverVehicle();
        return;
      }
      try {
        this.$root.$loading.show();
        const result = await this.$_services.order.getOrderById(this.item.id, source);
        this.$set(this.item, 'subOrder', result.data);
        this.setDialogSelectDriverVehicle(result.data);
      } finally {
        this.$root.$loading.hide();
      }
    },
    async handleAssignConfirmationMultiTransport() {
      try {
        const form = {
          confirm: true,
          notes: '',
          datetimeTz: this.getDateTimeTz(),
        };
        this.$root.$loading.show();
        await this.$_services.order.assignmentConfirmation(this.item.id, form, source);
        this.$dialog.notify.success('Pesanan berhasil diterima');
        this.$emit('fetchOrders');
        this.checkOrderExist();
      } finally {
        this.$root.$loading.hide();
      }
    },
    async assigmentConfirmation() {
      if (this.item.transportModel === 'LAND') {
        this.handleAssignConfirmationLandTransport();
        return;
      }
      this.handleAssignConfirmationMultiTransport();
    },
  },
};
</script>
