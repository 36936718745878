<template>
  <section>
    <v-row>
      <v-col>
        <h4 class="pl-2">{{$_strings.order.QUANTITY_INFO}}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-1">
        <v-data-table
          :headers="displayedHeaders"
          :items="items"
          class="grey lighten-4"
          hide-default-footer
        >
          <template v-slot:[`item.calculation`]={item}>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="grey"
                  small
                  icon
                  :disabled="disableBtnQty"
                  @click="setDialogChangeQuantity(item)"
                >
                  <v-icon>
                    mdi-autorenew
                  </v-icon>
                </v-btn>
              </template>
              <span>Hitung quantity</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.action`]>
            <v-btn
              v-if="showBtnCalculate"
              small
              color="primary"
              @click="$emit('viewDetail')"
            >
              Proses Hitung
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogChangeQuantity
      @refetchSubOrder="refetchSubOrder"
      :isDelivery="$route.params.status === 'process'"
      ref='dialogChangeQuantity'
    />
  </section>
</template>

<script>
import DialogChangeQuantity from '../Dialog/ChangeQuantity.vue';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DialogChangeQuantity,
  },
  data() {
    return {
      headers: [
        {
          text: '',
          value: 'calculation',
          width: '5vw',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.ORDER_NUMBER,
          value: 'shipmentsTitle',
          width: '15vw',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.KILO,
          value: 'kilo',
          width: '10vw',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.KUBIKASI,
          value: 'cubic',
          width: '10vw',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.BOX,
          value: 'box',
          width: '10vw',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: '',
          value: 'action',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    displayedHeaders() {
      const { status } = this.$route.params;
      const { canUpdate } = this.userAccess;
      const headers = this.headers.filter((header) => {
        if ((!canUpdate && (header.value === 'calculation' || header.value === 'action')) || (status === 'process' && header.value === 'action')) return;
        return header;
      });
      return headers;
    },
    items() {
      const { serviceType, latestStatus } = this.item;
      const { isCalculated } = this.item.subOrder;
      return this.item.subOrder.shipments.map((_shipment) => ({
        id: _shipment.id,
        shipmentsCargoId: _shipment.shipmentsCargoId,
        shipmentsId: _shipment.shipmentsId,
        shipmentsTitle: _shipment.shipmentsTitle,
        kilo: _shipment.tonnage,
        cubic: _shipment.cubic,
        box: _shipment.carton,
        latestStatus,
        serviceType,
        isCalculated,
      }));
    },
    disableBtnQty() {
      const statusNotAllowed = ['WAKTU_HABIS', 'KEDALUWARSA', 'DIBATALKAN'];
      if (this.item.subOrder.isCalculated
          || !this.userAccess.canUpdate
          || statusNotAllowed.includes(this.item.latestStatus)) return true;

      return false;
    },
    showBtnCalculate() {
      const statusNotAllowed = ['WAKTU_HABIS', 'KEDALUWARSA', 'DIBATALKAN'];
      if (!this.item.subOrder.isCalculated && !statusNotAllowed.includes(this.item.latestStatus) && this.userAccess.canUpdate) return true;
      return false;
    },
  },
  methods: {
    refetchSubOrder() {
      this.$delete(this.item, 'subOrder');
      this.$emit('fetchSubOrder', this.item);
    },
    setDialogChangeQuantity(item) {
      this.$refs.dialogChangeQuantity.orderSelected = item;
      this.$refs.dialogChangeQuantity.dialog = !this.$refs.dialogChangeQuantity.dialog;
    },
  },
};
</script>
