<template>
  <section>
    <v-row>
      <v-col>
        <h4 class="pl-2">{{$_strings.order.MODA_INFO}}</h4>
        <span class="pl-2 d-block">{{$_strings.order.VEHICLE_INFO}}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-1">
        <v-data-table
          :headers="displayedHeaders"
          :items="items"
          calculate-widths
          class="grey lighten-4"
          hide-default-footer
        >
          <template v-slot:[`item.estimateDeparture`]="{item}" >
            <span>{{item.estimateDeparture && dateFormat(item.estimateDeparture) || '-'}}</span>
          </template>
          <template v-slot:[`item.estimateArrival`]="{item}" >
            <span>{{item.estimateArrival && dateFormat(item.estimateArrival) || '-'}}</span>
          </template>
          <template v-slot:[`item.multiModaAction`]="{item}" >
            <v-tooltip
              v-if="showBtnUploadDocPod(item.latestStatus)"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="navigateToPodDocument(item)"
                  color="primary"
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-file-upload
                  </v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.order.UPLOAD_DOCUMENT_POD}}</span>
            </v-tooltip>
            <v-tooltip v-else-if="status !== 'finish'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="userAccess.canUpdate"
                  @click="setDialogMultimoda"
                  color="primary"
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-circle-edit-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.order.UPDATE}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`] >
            <v-tooltip v-if="status !== 'finish'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="setDialogSelectDriverVehicle"
                  color="primary"
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :disabled="disableBtnSelectDriver"
                >
                  <v-icon>
                    mdi-circle-edit-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  status === 'waiting' ?
                    $_strings.order.ASSIGN_DRIVER
                    :$_strings.order.UPDATE
                }}
              </span>
            </v-tooltip>
            <section v-if="status !== 'waiting'" class="d-inline">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="userAccess.canUpdate"
                    @click="setDialogShippingCostOrAdditionalCost('dialogShippingCost')"
                    color="primary"
                    class="ml-2"
                    small
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-img
                      :src="require('../../../assets/images/dolar.png')"
                      height="45"
                      width="45"
                    />
                  </v-btn>
                </template>
                <span>{{$_strings.order.LIST_SHIPPING_COST}}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="userAccess.canUpdate"
                    @click="setDialogShippingCostOrAdditionalCost('dialogAdditionalCost')"
                    color="primary"
                    class="ml-2"
                    small
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-img
                      :src="require('../../../assets/images/rupiah.png')"
                      height="45"
                      width="45"
                    />
                  </v-btn>
                </template>
                <span>{{$_strings.order.LIST_ADDITIONAL_COST}}</span>
              </v-tooltip>
            </section>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogShippingCost
      ref="dialogShippingCost"
      :page="status.toUpperCase()"
    />
    <DialogListAddtioanlCost
      ref="dialogAdditionalCost"
      :page="status.toUpperCase()"
    />
    <DialogUpdateMultimoda
      @refetchSubOrder="refetchSubOrder"
      ref="dialogUpdateMultimoda"
    />
    <DialogAssignDriverVehicle
      ref="dialogSelectAssignDriverVehicle"
      title="Pilih Kendaraan dan Sopir"
      :service="serviceAssignDriverVehicle"
      :afterAction="() => $emit('fetchOrders')"
    />
  </section>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat, timeFormat } from '@/helper/commonHelpers';
import DialogUpdateMultimoda from '../Dialog/UpdateMultimoda';
import DialogAssignDriverVehicle from '../Dialog/ChangeDriver.vue';
import DialogShippingCost from '../Dialog/ListShippingCost.vue';
import DialogListAddtioanlCost from '../Dialog/ListAddtionalCost.vue';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    status: {
      type: String,
      default: '',
    },
  },
  components: {
    DialogUpdateMultimoda,
    DialogAssignDriverVehicle,
    DialogShippingCost,
    DialogListAddtioanlCost,
  },
  data() {
    return {
      headers: [
        {
          text: this.$_strings.order.TYPE_TRANSPORTER,
          value: 'transportTypeName',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.VEHICLE_NUMBER,
          value: 'companyTransportNo',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.DRIVER,
          value: 'companyDriverName',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.NO_TELP,
          value: 'companyDriverPhone',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: 'Aksi',
          value: 'actions',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
      ],
      headersMultimoda: [
        {
          text: this.$_strings.order.NO_CONTAINER,
          value: 'containerNo',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.NO_CRUISE_FLIGHTS,
          value: 'flightCruiseNo',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.SHIP_NAME,
          value: 'transportTypeName',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.CRUISE_NAME_MASKAPAI,
          value: 'flightCruiseName',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.ESTIMATION_OF_DEPARTURE,
          value: 'estimateDeparture',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.ESTIMATION_ARRIVED,
          value: 'estimateArrival',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: 'Aksi',
          value: 'multiModaAction',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    displayedHeadersModaInfo() {
      const { canUpdate } = this.userAccess;
      return this.headers.filter((header) => {
        if (header.value === 'assignDriver' && !canUpdate) return;
        return header;
      });
    },
    displayedHeadersMultimodaInfo() {
      const { canUpdate } = this.userAccess;
      return this.headersMultimoda.filter((header) => {
        if (header.value === 'multiModaAction' && !canUpdate) return;
        return header;
      });
    },
    displayedHeaders() {
      if (this.item.transportModel !== 'LAND') {
        return this.displayedHeadersMultimodaInfo;
      }
      return this.displayedHeadersModaInfo;
    },
    disableBtnSelectDriver() {
      const statusAllowed = ['terkonfirmasi', 'waktu_habis'];
      if (this.status === 'waiting') return this.item.isAutoAssign;
      return !statusAllowed.includes(this.item.latestStatus.toLowerCase());
    },
    items() {
      return [{ ...this.item, ...this.item.subOrder }];
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    dayjs,
    showBtnUploadDocPod(latestStatus) {
      const allowStatus = ['BARANG_DITERIMA_UTUH', 'DOKUMEN_TIDAK_LENGKAP'];
      if (allowStatus.includes(latestStatus) && this.userAccess.canUpdate) return true;
      return false;
    },
    navigateToPodDocument() {
      this.$router.push({
        name: 'dokumen-pod-list-detail',
        params: {
          shipmentCargoId: this.item.id,
        },
        query: {
          cargoTitle: this.item.cargoTitle,
          dropDate: `qbetween(${dayjs(this.item.pickupDate).format('YYYY-MM-DD')},${dayjs(this.item.estimateArrival).format('YYYY-MM-DD')})`,
        },
      });
    },
    checkOrderExist() {
      if (this.status === 'waiting') this.$emit('orderExists');
    },
    serviceAssignDriverVehicle() {
      return this.$_services.order.saveDriver;
    },
    setDialogSelectDriverVehicle() {
      this.$refs.dialogSelectAssignDriverVehicle.shipment = this.item;
      this.$refs.dialogSelectAssignDriverVehicle.transportTypeId = this.item.subOrder.transportTypeId;
      this.$refs.dialogSelectAssignDriverVehicle.form.shipmentCargoId = this.item.id;
      this.$refs.dialogSelectAssignDriverVehicle.dialog = true;
    },
    refetchSubOrder() {
      this.$delete(this.item, 'subOrder');
      this.$emit('fetchSubOrder', this.item);
      this.checkOrderExist();
    },
    setDialogShippingCostOrAdditionalCost(ref) {
      this.$refs[ref].itemSelected = { ...this.item, ...this.item.subOrder };
      this.$refs[ref].dialog = true;
    },
    setDialogMultimoda() {
      this.$refs.dialogUpdateMultimoda.shipment = this.item;
      this.$refs.dialogUpdateMultimoda.dialog = true;
    },
  },
};
</script>
